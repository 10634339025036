import "assets/css/bootstrapCustom.scss";
import 'react-image-lightbox/style.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
// import "assets/css/sass/web/style.css";
import {
    isMultiColorActive,
    defaultColor,
    themeColorStorageKey,
    isDarkSwitchActive,
} from "constants/defaultValues";

import jquery from "jquery";
window.$ = window.jQuery = jquery;

const color =
    (isMultiColorActive || isDarkSwitchActive) &&
    localStorage.getItem(themeColorStorageKey)
        ? localStorage.getItem(themeColorStorageKey)
        : defaultColor;

localStorage.setItem(themeColorStorageKey, color);

let render = () => {
    import("assets/css/sass/themes/gogo." + color + ".scss").then(() => {
        require(/* webpackChunkName: "AppRenderer" */ "./AppRenderer");
    });
};

render();
